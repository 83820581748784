import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
// import logo from '../assets/img/logo.svg';
// import { HashLink } from 'react-router-hash-link';
import {
  BrowserRouter as Router
} from "react-router-dom";
import Logo from "./Logo";
import { NavLink } from "react-router-dom";
import SocialLinks from "./SocialLinks";

export const NavBar = () => {

  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    const currentUrl = window.location.pathname;
    const arrUrl = currentUrl.split("/");
    const elem_id = arrUrl[arrUrl.length - 1];
    if (elem_id !== "") {
      // console.log(`scroll to ${elem_id}`);
      handleClickScroll(elem_id);
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const handleClickScroll = (elem_id) => {
    setActiveLink(elem_id);
    const element = document.getElementById(`${elem_id}`);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    } else {
      window.scrollTo(0, 0);
    }
  };

  return (
    <Router>
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <Container>
          <Navbar.Brand as={NavLink} to="/" onClick={() => handleClickScroll('')}>
            {/* <img src={logo} alt="Logo" /> */}
            {/* <Nav className="ms-auto"> */}
            {/* <Nav.Link as={NavLink} to="/" onClick={() => handleClickScroll('')}> */}
            <Logo />
            {/* </Nav.Link> */}
            {/* </Nav> */}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              {/* <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link> */}
              <Nav.Link className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'}
                as={NavLink} to="/skills" onClick={() => handleClickScroll('skills')}
              >Skills</Nav.Link>
              <Nav.Link className={activeLink === 'certifications' ? 'active navbar-link' : 'navbar-link'}
                as={NavLink} to="/certifications" onClick={() => handleClickScroll('certifications')}
              >Certifications</Nav.Link>
              <Nav.Link className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'}
                as={NavLink} to="/projects" onClick={() => handleClickScroll('projects')}
              >Projects</Nav.Link>
            </Nav>
            <span className="navbar-text">
              <SocialLinks />
              {/* <HashLink to='#connect'>
                <button className="vvd"><span>Let’s Connect</span></button>
              </HashLink> */}
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Router>
  )
}
