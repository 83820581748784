import { Container, Row, Col } from "react-bootstrap";
// import { MailchimpForm } from "./MailchimpForm";
// import logo from "../assets/img/logo.svg";
import SocialLinks from "./SocialLinks";
// import reactLogo from "../assets/img/react18.svg";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          {/* <MailchimpForm /> */}
          <Col size={12} sm={6}>
            <SocialLinks />
            {/* <img src={logo} alt="Logo" /> */}
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <p>Handmade with <span className="text-danger">&#10084;</span>&nbsp;
              {/* <img src={reactLogo} alt="ReactJS-18" /> */}
              {/* <img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original-wordmark.svg" alt="react" width="40" height="40" style={{ maxWidth: "100%" }}></img> */}
              <i class="devicon-react-original-wordmark colored" style={{ fontSize: 24 }}></i>
              &nbsp;<span className="text-danger">18</span>
              &nbsp;<span className="text-danger">&#10084;</span>&nbsp; by Mubin Khalife</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
