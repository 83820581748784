import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { useForm } from "react-hook-form";
import TextInputField from "./TextInputField";

export const Contact = () => {
  // const formInitialDetails = {
  //   firstName: '',
  //   lastName: '',
  //   email: '',
  //   phone: '',
  //   message: ''
  // }
  // const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Send');
  const [status, setStatus] = useState({});

  // const onFormUpdate = (category, value) => {
  //   setFormDetails({
  //     ...formDetails,
  //     [category]: value
  //   })
  // }

  const { register, handleSubmit, getValues, reset, formState: { errors, isSubmitting } } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      message: ''
    }
  });

  const onSubmit = async (_, e) => {
    try {
      e.preventDefault();
      const formDetails = getValues();
      // console.log("Sending from data:", formDetails);
      // return;
      setButtonText("Sending...");
      // let response = await fetch("http://mail-sender.mubin-khalife.digital/sendmail.php", {
      let response = await fetch("https://mubin-khalife.digital/sendmail.php", {
        method: "POST",
        headers: {
          "Content-type": "application/json;charset=utf-8",
          // "Content-Type": "application/json;charset=utf-8",
        },
        mode: "no-cors",
        cache: "no-cache",
        redirect: "follow",
        // referrerPolicy: "no-referrer",
        // dataType: 'json',
        body: JSON.stringify(formDetails),
      });
      setButtonText("Send");
      // setFormDetails(formInitialDetails);
      let result = await response.json();
      // console.log(result.data);
      // console.log(result.message);
      reset();
      if (result.message === "success") {
        setStatus({ succes: "yes", message: 'Message sent successfully' });
      } else {
        setStatus({ succes: "no", message: 'Something went wrong, please try again later.' });
      }
    } catch (err) {
      console.log(err);
      setStatus({ succes: "no", message: 'Something went wrong, please try again later.' });
    } finally {
      setButtonText("Send");
      const btnSubmit = document.getElementById("btnSubmit");
      btnSubmit.removeAttribute("disabled");

      setTimeout(() => {
        setStatus({});
      }, 5000);
    }
  };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={contactImg} alt="Contact Us" />
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2>Get In Touch</h2>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col size={12} sm={6} className="px-1">
                        <TextInputField
                          name="firstName"
                          label="First Name"
                          type="text"
                          placeholder="First Name"
                          register={register}
                          registerOptions={{ required: "Required", pattern: { value: /^[a-z'-]+$/i, message: "Please Enter First Name" } }}
                          error={errors.firstName}
                        />
                        {/* <input type="text" value={formDetails.firstName} placeholder="First Name" onChange={(e) => onFormUpdate('firstName', e.target.value)} /> */}
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <TextInputField
                          name="lasttName"
                          label="Last Name"
                          type="text"
                          placeholder="Last Name"
                          register={register}
                          registerOptions={{ required: "Required", pattern: { value: /^[a-z ,.'-]+$/i, message: "Please Enter Valid Last Name" } }}
                          error={errors.lasttName}
                        />
                        {/* <input type="text" value={formDetails.lasttName} placeholder="Last Name" onChange={(e) => onFormUpdate('lastName', e.target.value)} /> */}
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <TextInputField
                          name="email"
                          label="Email"
                          type="email"
                          placeholder="Enter your Email"
                          register={register}
                          registerOptions={{ required: "Required", pattern: { value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, message: "Please Enter Valid Email" } }}
                          error={errors.email}
                        />
                        {/* <input type="email" value={formDetails.email} placeholder="Email Address" onChange={(e) => onFormUpdate('email', e.target.value)} /> */}
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        {/* <input type="tel" placeholder="Phone No." /> */}
                        <TextInputField
                          name="phone"
                          label="Phone"
                          type="text"
                          placeholder="Enter phone"
                          register={register}
                          registerOptions={{ pattern: { value: /\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/, message: "Please Enter Valid Phone" } }}
                          error={errors.phone}
                        />
                        {/* <input type="tel" value={formDetails.phone} placeholder="Phone No." onChange={(e) => onFormUpdate('phone', e.target.value)} /> */}
                      </Col>
                      <Col size={12} className="px-1">
                        <TextInputField
                          name="message"
                          label="Message"
                          as="textarea"
                          rows={6}
                          placeholder="Enter Text"
                          register={register}
                          registerOptions={{ required: "Required" }}
                          error={errors.message}
                        />
                        {/* <textarea rows="6" value={formDetails.message} placeholder="Message" onChange={(e) => onFormUpdate('message', e.target.value)}></textarea> */}
                        <button type="submit" id="btnSubmit" disabled={isSubmitting}><span>{buttonText}</span></button>
                        {/* <Button type="button" disabled={isSubmitting}>{buttonText}</Button> */}
                      </Col>
                    </Row>
                    <Row>
                      {
                        status.message &&
                        <Col className={`mt-4 ${status.succes === "no" ? "bg-danger" : "bg-success"}`}>
                          <p className="h4 text-white">{status.message}</p>
                        </Col>
                      }
                    </Row>
                  </form>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
