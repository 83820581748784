// import meter1 from "../assets/img/meter1.svg";
// import meter2 from "../assets/img/meter2.svg";
// import meter3 from "../assets/img/meter3.svg";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.png"
import data_analyst_certificate from "../assets/img/data_analyst_certificate.jpeg";
import devops_certificate from "../assets/img/devops_certificate.jpeg";

export const Certifications = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <section className="certifications" id="certifications">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="skill-bx wow zoomIn">
                            <h2>My Certifications</h2>
                            <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                                <a href='https://www.coursera.org/account/accomplishments/specialization/certificate/CZ7UEGYVSMBQ' target='_blank' rel="noreferrer">
                                    <div className="item">
                                        <img src={data_analyst_certificate} title="Google Certified Data Analyst Certificate" alt="Google Certified Data Analyst Certificate" style={{ "width": "100%", height: 512, objectFit: 'cover' }} />
                                        {/* <img src="https://s3.amazonaws.com/coursera_assets/meta_images/generated/CERTIFICATE_LANDING_PAGE/CERTIFICATE_LANDING_PAGE~CZ7UEGYVSMBQ/CERTIFICATE_LANDING_PAGE~CZ7UEGYVSMBQ.jpeg" title="Google Certified Data Analyst Certificate" alt="Google Certified Data Analyst Certificate" /> */}
                                    </div>
                                </a>
                                <a href='https://udemy-certificate.s3.amazonaws.com/image/UC-77f5ec40-7aad-46f2-b2eb-2b720c83ca89.jpg?v=1681889942000' target='_blank' rel="noreferrer">
                                    <div className="item" >
                                        <img src={devops_certificate} alt="DevOps Certification" title='DevOps Certification' style={{ "width": "100%", height: 512, objectFit: 'cover' }} />
                                        {/* <img src="https://udemy-certificate.s3.amazonaws.com/image/UC-77f5ec40-7aad-46f2-b2eb-2b720c83ca89.jpg?v=1681889942000" alt="DevOps Certification" title='DevOps Certification' /> */}
                                    </div>
                                </a>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
            <img className="background-image-left" src={colorSharp} alt="certifications" />
        </section>
    )
}
