// import meter1 from "../assets/img/meter1.svg";
// import meter2 from "../assets/img/meter2.svg";
// import meter3 from "../assets/img/meter3.svg";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.png"

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>My Skills</h2>
              {/* <p>I have following skillsets</p> */}
              <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                <div className="item">
                  {/* <img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg" alt="Python 3" title='Python 3' /> */}
                  {/* <img src={require('../assets/img/skill_icons/python.svg')} alt="Python 3" title='Python 3' /> */}

                  <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original-wordmark.svg" alt="Python 3" title='Python 3' />
                </div>

                <div className="item">
                  {/* <img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg" alt="ES6" title='ES6 - JavaScript' /> */}
                  {/* <img src={<Javascript />} alt="JavaScript" title='JavaScript' /> */}
                  <i class="devicon-javascript-plain colored" style={{ fontSize: 128 }}></i>
                </div>

                <div className="item">
                  {/* <img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original-wordmark.svg" title="ReactJS" alt="ReactJS" /> */}
                  {/* <img src={require('../assets/img/skill_icons/react.svg')} title="ReactJS" alt="ReactJS" /> */}
                  <i class="devicon-react-original-wordmark colored" style={{ fontSize: 128 }}></i>
                </div>

                <div className="item">
                  {/* <img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original.svg" title='MongoDB' alt="mongodb" /> */}
                  {/* <img src={require('../assets/img/skill_icons/mongo.svg')} alt="MongoDB" title='MongoDB' /> */}

                  <i class="devicon-mongodb-plain-wordmark colored" style={{ fontSize: 128 }}></i>

                </div>
                <div className="item">
                  {/* <img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/express/express-original.svg" title="ExpressJS" alt="ExpressJS" style={{ maxWidth: "100%", backgroundColor: "white" }} /> */}
                  {/* <img src={require('../assets/img/skill_icons/express.svg')} alt="ExpressJS" title='ExpressJS' /> */}

                  <i class="devicon-express-original-wordmark" style={{ fontSize: 128 }}></i>

                </div>

                <div className="item">
                  {/* <img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original-wordmark.svg" title="NodeJS" alt="NodeJS" /> */}
                  {/* <img src={require('../assets/img/skill_icons/node.svg')} title="NodeJS" alt="NodeJS" /> */}

                  <i class="devicon-nodejs-plain-wordmark colored" style={{ fontSize: 164 }}></i>

                </div>

                <div className="item">
                  <img src={require('../assets/img/skill_icons/react_native.png')} title="React Native" alt="React Native" style={{ width: 224 }} />
                </div>

                <div className="item">

                  <i class="devicon-django-plain-wordmark colored" style={{ fontSize: 144, paddingLeft: 10, paddingRight: 10, backgroundColor: 'white' }}></i>
                </div>

                {/* <div className="item">
                  <i class="devicon-nextjs-original-wordmark" style={{ fontSize: 128 }}></i>
                </div> */}
                {/* <div className="item"> */}
                {/* <img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/typescript/typescript-original.svg" title='TypeScript' alt="typescript" /> */}
                {/* <img src={require('../assets/img/skill_icons/typescript.svg')} title='TypeScript' alt="TypeScript" /> */}
                {/* </div> */}

                <div className="item">
                  {/* <img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original-wordmark.svg" title='HTML' alt="html" width="64" height="64" style={{ maxWidth: "100%", backgroundColor: "white" }} /> */}
                  {/* <img src={require('../assets/img/skill_icons/html.svg')} title='HTML' alt="HTML" /> */}

                  <i class="devicon-html5-plain-wordmark colored" style={{ fontSize: 128 }}></i>

                </div>
                <div className="item">
                  {/* <img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/php/php-original.svg" title='php' alt="PHP" /> */}
                  {/* <img src={require('../assets/img/skill_icons/php.svg')} title='PHP' alt="PHP" /> */}

                  <i class="devicon-php-plain colored" style={{ fontSize: 128 }}></i>

                </div>
                <div className="item">

                  <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original-wordmark.svg" title='MySQL' alt="MySQL" />
                  {/* <img src={require('../assets/img/skill_icons/mysql.svg')} title='MySQL' alt="MySQL" /> */}

                  {/* <i class="devicon-mysql-plain-wordmark colored" style={{ fontSize: 128 }}></i> */}

                </div>
                <div className="item">
                  {/* <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/39/Kubernetes_logo_without_workmark.svg/2109px-Kubernetes_logo_without_workmark.svg.png" alt="kubernetes" title='Kubernetes' /> */}
                  {/* <img src={require('../assets/img/skill_icons/kubernetes.svg')} alt="Kubernetes" title='Kubernetes' /> */}

                  <i class="devicon-kubernetes-plain-wordmark colored" style={{ fontSize: 128 }}></i>

                </div>
                <div className="item">
                  {/* <img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/docker/docker-original.svg" alt="Docker" title='Docker' /> */}
                  {/* <img src={require('../assets/img/skill_icons/docker.svg')} alt="Docker" title='Docker' /> */}

                  <i class="devicon-docker-plain-wordmark colored" style={{ fontSize: 128 }}></i>

                </div>
                <div className="item">
                  {/* <img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/jenkins/jenkins-original.svg" alt="Jenkins" title='Jenkins' /> */}
                  {/* <img src={require('../assets/img/skill_icons/jenkins.svg')} alt="Jenkins" title='Jenkins' /> */}

                  <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/jenkins/jenkins-original.svg" alt="Jenkins" title='Jenkins' />

                </div>
                {/* <div className="item">
                  <img src="https://logowik.com/content/uploads/images/microsoft-excel-2013.jpg" alt="Excel" title='Excel' />
                </div> */}
                {/* <div className="item"> */}
                {/* <img src={require('../assets/img/skill_icons/jupyter.svg')} alt="Jupyter" title='Jupyter' /> */}

                {/* <i class="devicon-jupyter-plain-wordmark colored" style={{ fontSize: 128 }}></i>

                </div>
                <div className="item"> */}
                {/* <img src={require('../assets/img/skill_icons/pandas.svg')} alt="Pandas" title='Pandas' /> */}

                {/* <i class="devicon-pandas-original-wordmark" style={{ fontSize: 128 }}></i>

                </div>
                <div className="item"> */}
                {/* <img src={require('../assets/img/skill_icons/numpy.svg')} alt="Numpy" title='Numpy' /> */}
                {/* <i class="devicon-numpy-original-wordmark" style={{ fontSize: 164 }}></i>
                </div> */}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="Skills" />
    </section>
  )
}
