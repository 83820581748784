import navIcon1 from '../assets/img/navIcon1.svg';
import navIcon2 from '../assets/img/navIcon2.svg';
import navIcon3 from '../assets/img/navIcon3.svg';

const SocialLinks = () => {
    return (
        <div className="social-icon">
            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/mubin-khalife/"><img src={navIcon1} alt="" /></a>
            <a target="_blank" rel="noreferrer" href="https://github.com/khalifemubin"><img src={navIcon2} alt="" /></a>
            <a target="_blank" rel="noreferrer" href="https://twitter.com/@MubinKhalife"><img src={navIcon3} alt="" /></a>
        </div>
    );
}

export default SocialLinks