import { useState, useEffect, useCallback, useMemo } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/header-img.svg";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import {
  BrowserRouter as Router
} from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  // const [index, setIndex] = useState(1);
  const toRotate = useMemo(() => ["Full-Stack Developer", "Backend Developer", "Hybrid Mobile Developer", "DevOps"], []);
  const period = 2000;

  const tick = useCallback(() => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      // setDelta(prevDelta => prevDelta / 2);
      setDelta(5);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      // setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      // setIndex(1);
      // setDelta(500);
      setDelta(50);
    } else {
      // setIndex(prevIndex => prevIndex + 1);
    }
  }
    , [isDeleting, loopNum, text.length, toRotate])

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [delta, tick])

  const handleClickScroll = (elem_id) => {
    const element = document.getElementById(`${elem_id}`);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <span className="tagline">Welcome to my Portfolio</span>
                  <h1>{`Hi! This is Mubin`} <br /><div className="mt-4 txt-rotate"
                    // dataPeriod="1000"
                    data-rotate='[ "Full-Stack Developer", "Back-end Developer", "Hybrid Mobile Developer", "DevOps" ]'><span className="wrap">{text}</span></div></h1>
                  <p>I am a polyglot developer who has developed and deployed full-stack web apps and cross-platform mobile apps using multiple tech stacks.</p>
                  <p>Oh! And I can wear different hats too!</p>
                  <p>
                    Data Analysis? - No problem!
                    <br />Technical/Content Writing - Easy Peasy!
                    <br />Mobile App? - Gotcha!
                    {/* <br />Digital Marketing? - Challenge accepted! */}
                  </p>
                  <span className="navbar-text">
                    {/* <button onClick={() => window.location.href = "#connect"} className="vvd"><span>Let’s Connect <ArrowRightCircle size={25} /></span></button> */}

                    {/* <button onClick={() => handleClickScroll('connect')} className="vvd"><span>Let’s Connect <ArrowRightCircle size={25} /></span></button> */}

                    <Router>
                      <HashLink to='/connect' onClick={() => handleClickScroll('connect')} className="btnLetsConnect">
                        <button className="vvd"><span>Let’s Connect<ArrowRightCircle size={25} /></span></button>
                      </HashLink>
                      {/* <Navbar expand="md">
                        <Nav className="ms-auto"> */}
                      {/* <Nav.Link
                        as={NavLink} exact to="/connect" onClick={() => handleClickScroll('connect')} className="vvd"
                      ><span>Let’s Connect <ArrowRightCircle size={25} /></span></Nav.Link> */}
                      {/* </Nav>
                      </Navbar> */}
                    </Router>

                  </span>
                  {/* <button onClick={() => window.location.href = "#connect"}>Let’s Connect <ArrowRightCircle size={25} /></button> */}
                </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={headerImg} alt="Header Img" />
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
