// import { Container, Row, Col } from "react-bootstrap";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
// import projImg1 from "../assets/img/project-img1.png";
// import projImg2 from "../assets/img/project-img2.png";
// import projImg3 from "../assets/img/project-img3.png";
import zencommerce_stores from "../assets/img/zencommerce_stores.png";
import jalongi from "../assets/img/jalongi.png";
import hul_project from "../assets/img/hul_project.png";
import hvy from "../assets/img/hvy.png";
import basket360 from "../assets/img/basket360.png";
import bombayfresh from "../assets/img/bombayfresh.png";
import ubl_bps from "../assets/img/ubl_bps.png";
import mycasting from "../assets/img/mycasting.png";
import notes_app from "../assets/img/notes_app.png";
import first_devops_article from "../assets/img/first_devops_article.png";
import analysis_project from "../assets/img/analysis_project.png";
import first_article from "../assets/img/first_article.png";
import medium_general from "../assets/img/medium_general.png";
import airbnb_clone from "../assets/img/airbnb_clone.png";
import nike_clone from "../assets/img/nike-clone.png";
import stackoverflow_clone from "../assets/img/stackoverflow-clone.png";

import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "Nike Store Clone",
      description: "A fullstack NextJS 13 app with Strapi CMS for Admin, Stripe Payment gateway and Cloud RDBMS. Deployed frontend on Vercel and backend on Render",
      imgUrl: nike_clone,
      remoteLink: "https://nike-store-nextjs-frontend.vercel.app"
    }
    ,
    {
      title: "AirBnB Clone",
      description: "A fullstack NextJS 13 app with Prisma for MySQL, Google and GitHub login. Deployed on Vercel. Planetscale for cloud RDBMS.",
      imgUrl: airbnb_clone,
      remoteLink: "https://nextjs-bnb-clone.vercel.app"
    }
    ,
    {
      title: "Jalongi",
      description: "An online marketplace for Fish. A full-stack app with Admin panel.",
      imgUrl: jalongi,
      remoteLink: "https://jalongi.com"
    }
    ,
    {
      title: "Luxembourg Consular Service",
      description: "A convenient online service to book appointments with Indian Embassy in Luxembourg. A full-stack app with Admin panel",
      imgUrl: hul_project,
      remoteLink: "https://www.hcgiluxembourg.lu/"
    }
    ,
    {
      title: "HomeVillaYoga",
      description: "Choose and Book a Yoga teacher and learn from the comfort of your home. A full-stack app with Admin panel",
      imgUrl: hvy,
      remoteLink: "http://homevillayoga.com"
    }
    ,
    {
      title: "Zencommerce Stores",
      description: "Various Applications for Merchant stores on Zencommerce platform",
      imgUrl: zencommerce_stores,
      remoteLink: "https://whmcs.zencommerce.in/index.php?rp=/knowledgebase/120/Applications"
    },
    {
      title: "Basket360",
      description: "One of the Android application (webview) for a Zencommerce's client",
      imgUrl: basket360,
      remoteLink: "https://play.google.com/store/apps/details?id=com.basket360"
    }
    ,
    {
      title: "BombayFresh",
      description: "Another one of the Android application (webview) for a Zencommerce's client",
      imgUrl: bombayfresh,
      remoteLink: "https://play.google.com/store/apps/details?id=com.bombayfresh"
    }
    ,
    {
      title: "UBL Barley Procurement System",
      description: "A cross platform app, developed in single Ionic framework codebase, for the procurement of barley from vendors",
      imgUrl: ubl_bps,
      remoteLink: "https://play.google.com/store/apps/details?id=com.ubl.bps"
    }
    ,
    {
      title: "MyCastingNet",
      description: "A web platform for connecting talents with different production studios. Built with ASP.NET",
      imgUrl: mycasting,
      remoteLink: "https://www.mycastingnet.com"
    }
    ,
    {
      title: "Stackoverflow Clone",
      description: "A bare minimum fullstack MERN app with MongoDB, ExpressJS, ReactJS and NodeJS. Hosted on Render.com",
      imgUrl: stackoverflow_clone,
      remoteLink: "https://mubin-stackoverflow-clone.onrender.com"
    }
    ,
    {
      title: "Notes App",
      description: "A Full-Stack (MERN) app created from using TypeScript with Sequelize replacing Mongoose. Hosted on a shared platform",
      imgUrl: notes_app,
      remoteLink: "http://mern-typescript-notes.mubin-khalife.digital"
    },
  ];

  const writings = [
    {
      title: "End to End DevOps Project",
      description: "A CI/CD project using Docker, Jenkins, Ansible & Minikube on AWS. Check the stats for the article in this image",
      imgUrl: first_devops_article,
      remoteLink: "https://medium.com/@mubin.khalife/devops-ci-cd-project-using-docker-jenkins-ansible-minikube-for-poc-development-62ba2da07a92"
    },
    {
      title: "Google Data Analytics Capstone",
      description: "An article about my Capstone project for Google Data Analytics Certification",
      imgUrl: analysis_project,
      remoteLink: "https://medium.com/@mubin.khalife/google-data-analytics-capstone-cyclistic-e027437bf278"
    },
    {
      title: "Web Scraping in PHP",
      description: "An article about using Goutte library for web scraping. GitHub is used as an example to scrape for information.",
      imgUrl: first_article,
      remoteLink: "https://medium.com/@mubin.khalife/php-web-scraping-youve-goutte-a-friend-5dd9d2874b80"
    },
    {
      title: "My Medium Profile",
      description: "Click to see my other writing works",
      imgUrl: medium_general,
      remoteLink: "https://medium.com/@mubin.khalife"
    },

  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2>Projects</h2>
                  <p>Following is a list of my work and my personal projects<br />(including this React Website)</p>
                  {/*
                  <Row>
                    {
                      projects.map((project, index) => {
                        return (
                          <ProjectCard
                            key={index}
                            {...project}
                          />
                        )
                      })
                    }
                  </Row>
                  */
                  }
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                      <Nav.Item>
                        <Nav.Link eventKey="first">Development</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Writing</Nav.Link>
                      </Nav.Item>
                      {/* <Nav.Item>
                        <Nav.Link eventKey="third">Writing</Nav.Link>
                      </Nav.Item> */}
                    </Nav>
                    <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                      <Tab.Pane eventKey="first">
                        <Row>
                          {
                            projects.map((project, index) => {
                              return (
                                <ProjectCard
                                  key={index}
                                  {...project}
                                />
                              )
                            })
                          }
                        </Row>
                      </Tab.Pane>
                      {/* <Tab.Pane eventKey="section">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                      </Tab.Pane> */}
                      <Tab.Pane eventKey="third">
                        <Row>
                          {
                            writings.map((writing, index) => {
                              return (
                                <ProjectCard
                                  key={index}
                                  {...writing}
                                />
                              )
                            })
                          }
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="Background"></img>
    </section>
  )
}
